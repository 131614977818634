export const getInitials = (value: string = '') =>
  `${value}`
    .split(' ')
    .slice(0, 2)
    .map((word) => word[0])
    .join('')
    .toUpperCase();

export const getFirstInitial = (value: string = '') =>
  getInitials(value)[0] ?? '';

export const clearLabelFromBrackets = (label: string) =>
  label.replace(/^\[\^(\d+)\]$/g, '$1');
