import { useCallback } from 'react';

import { AppDispatch } from 'app/state/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { DocumentDragItem } from 'containers/RetrievalUnit/RetrievalUnitDraggable';
import { addTagToDocument } from 'containers/SavedDocuments/savedDocuments.slice';

export interface UseDocumentDragTaggingReturn {
  handleDocumentDrop: (
    item: DocumentDragItem,
    tag: TagDetails
  ) => Promise<void>;
}

export const useDocumentDragTagging = (): UseDocumentDragTaggingReturn => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const { tenant } = useParsedHostname();

  const handleDocumentDrop = useCallback(
    async (item: DocumentDragItem, tag: TagDetails) => {
      await dispatch(
        addTagToDocument({
          docId: item.id,
          docType: item.docType,
          tagId: tag.id,
          tagType: tag.type,
          tenant,
          userOrder: 'first',
        })
      );

      enqueueSnackbar(
        t('tagDocument.addSingle.successMessage', { tagName: tag.name })
      );
    },
    [dispatch, enqueueSnackbar, tenant, t]
  );

  return {
    handleDocumentDrop,
  };
};
