import React, { FC } from 'react';

import {
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  Typography,
} from '@mui/material';

import { MarkdownText } from 'common/components/Markdown/MarkdownText';

interface Props extends CardProps {
  content: string;
  href?: string;
  subtitle?: string;
  title?: string;
}

export const SimpleCard: FC<Props> = ({
  content,
  href,
  subtitle,
  title,
  ...props
}) => {
  const Content = (
    <CardContent sx={{ m: 0, maxHeight: 220, overflow: 'hidden' }}>
      {title && (
        <Typography sx={{ fontSize: '130%', fontWeight: 'bold' }} variant="h6">
          {title}
        </Typography>
      )}
      {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      <Typography
        sx={{ color: 'text.secondary', fontSize: '95%' }}
        variant="body2"
      >
        <MarkdownText>{content}</MarkdownText>
      </Typography>
    </CardContent>
  );

  return (
    <Card elevation={9} {...props}>
      {href ? (
        <CardActionArea
          component="a"
          href={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {Content}
        </CardActionArea>
      ) : (
        Content
      )}
    </Card>
  );
};
