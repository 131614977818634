import { useTenantSettings } from './useTenantSettings';

export const useTenantFeatures = () => {
  const { tenantSettings } = useTenantSettings();

  const isExportBibtexInDoc =
    tenantSettings?.document?.exports?.includes('bibtex');
  const isExportBibtexInTag = tenantSettings?.tag?.exports?.includes('bibtex');
  const isShareToPublicInTag = tenantSettings?.tag?.sharing?.includes('public');
  const isShareToPublicInDoc =
    tenantSettings?.document?.sharing?.includes('public');
  const isShareToTeamInTag = tenantSettings?.tag?.sharing?.includes('team');
  const isShareToTeamInDoc =
    tenantSettings?.document?.sharing?.includes('team');
  const isShareToTeamInNote = tenantSettings?.note?.sharing?.includes('team');
  const isDocumentUploadsHidden =
    tenantSettings?.features?.documentUploads?.status === 'hidden';
  const isDocumentUploadsActive =
    tenantSettings?.features?.documentUploads?.status === 'active';
  const isDocumentUploadsDisabled =
    tenantSettings?.features?.documentUploads?.status === 'disabled';
  const isRecommendationsHidden =
    tenantSettings?.features?.recommendations?.status === 'hidden';
  const isRecommendationsActive =
    tenantSettings?.features?.recommendations?.status === 'active';
  const isRecommendationsDisabled =
    tenantSettings?.features?.recommendations?.status === 'disabled';
  const isPeopleFeatureHidden =
    tenantSettings?.features?.people?.status === 'hidden';
  const isPeopleFeatureActive =
    tenantSettings?.features?.people?.status === 'active';
  const isPeopleFeatureDisabled =
    tenantSettings?.features?.people?.status === 'disabled';

  return {
    isDocumentUploadsActive,
    isDocumentUploadsDisabled,
    isDocumentUploadsHidden,
    isExportBibtexInDoc,
    isExportBibtexInTag,
    isPeopleFeatureActive,
    isPeopleFeatureDisabled,
    isPeopleFeatureHidden,
    isRecommendationsActive,
    isRecommendationsDisabled,
    isRecommendationsHidden,
    isShareToPublicInDoc,
    isShareToPublicInTag,
    isShareToTeamInDoc,
    isShareToTeamInNote,
    isShareToTeamInTag,
  };
};
