import { useMemo } from 'react';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { useNotesList } from 'containers/SavedNotes/hooks/useNotesList';
import {
  addHighlightToAnnotationHighlight,
  addNoteHighlightTokens,
} from 'containers/SavedNotes/savedNotes.utils';

import { filterNoteHits } from '../searchResults.utils';

export const useSearchResultsNotes = (hits: RetrievalUnitData[] = []) => {
  const [noteHits, noteIds] = useMemo<[RetrievalUnitData[], number[]]>(() => {
    const notes = filterNoteHits(hits);
    return [notes, notes.map(({ identifier }) => Number(identifier))];
  }, [hits]);

  const parsedHostname = useParsedHostname();

  const {
    deleteNoteMutation,
    query: { data, ...restQuery },
    updateNoteMutation,
  } = useNotesList({
    noteIds,
    options: { enabled: !!noteIds.length },
    pageSize: noteIds.length,
    tenant: parsedHostname.tenant,
  });

  const notesData = useMemo<Record<number, NoteDetails>>(() => {
    if (!data) return {};

    return data.items.reduce(
      (
        acc: Record<number, NoteDetails>,
        { annotationHighlight, content, id, ...note }: NoteDetails
      ) => {
        const noteHit = noteHits.find(
          ({ identifier }) => Number(identifier) === id
        );

        if (!noteHit) return acc;

        const highlightTokens = noteHit.highlightTokens ?? [];

        acc[id] = {
          ...note,
          annotationHighlight: addHighlightToAnnotationHighlight(
            annotationHighlight,
            highlightTokens
          ),
          content,
          highlight: addNoteHighlightTokens(content, highlightTokens),
          id,
        };

        return acc;
      },
      {}
    );
  }, [data, noteHits]);

  return {
    deleteNoteMutation,
    query: { data: notesData, ...restQuery },
    updateNoteMutation,
  };
};
