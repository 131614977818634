import React, { FC, useCallback } from 'react';

import { noteShareableToSharing } from 'api/notesApi/notesApi';
import { NoteDetails, NoteObjectId } from 'api/notesApi/notesApi.types';
import { NoteCard } from 'common/components/Notes/NoteCard';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useSingleNote } from 'containers/SavedNotes/hooks/useSingleNote';

interface NoteInChatProps {
  noteId: number;
}

export const NoteInChat: FC<NoteInChatProps> = ({ noteId }) => {
  const { userRoleId } = useAuth();
  const { tenant } = useParsedHostname();

  const { deleteNoteMutation, query, updateNoteMutation } =
    useSingleNote(noteId);

  const handleDelete = useCallback(
    async (_: NoteObjectId) => {
      await deleteNoteMutation.mutateAsync(noteId);
    },
    [deleteNoteMutation, noteId]
  );

  const handleUpdate = useCallback(
    async (noteEl: NoteDetails) => {
      void updateNoteMutation.mutateAsync({
        annotationHighlight: noteEl.annotationHighlight,
        content: noteEl.content,
        noteId: noteEl.id,
        objectId: noteEl.objectId,
        objectType: noteEl.objectType,
        sharing: noteShareableToSharing(noteEl.shareable, userRoleId),
        tenant,
      });
    },
    [tenant, updateNoteMutation, userRoleId]
  );

  if (!query.data?.items.length) return null;

  return (
    <NoteCard
      note={query.data?.items[0]}
      readMode={true}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
    />
  );
};
