import React, { FC, ReactNode } from 'react';

import { ReferenceLink } from './ReferenceLink';
import { getDocumentId, getReferenceLinkId, isReferenceLabel } from './utils';

interface Props {
  children: ReactNode;
  href: string;
  title?: string;
}

export const LinkRenderer: FC<Props> = ({ children, href = '#', title }) => {
  const label = children?.toString() || '';
  const documentId = getDocumentId(href);
  const isReference = isReferenceLabel(label) || documentId;

  if (isReference) {
    const id = (getReferenceLinkId(label) || label || documentId) ?? '??';
    return <ReferenceLink href={href} label={id} title={title} />;
  }

  return (
    <a
      href={href}
      rel="noopener noreferrer"
      style={{ color: 'blue', textDecoration: 'underline' }}
      target="_blank"
    >
      {children}
    </a>
  );
};
