import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';

import { useDocDetails } from 'containers/Docs/hooks/useDocDetails';
import { SimpleCard } from 'containers/SearchResults/SimpleItem/SimpleCard';
import { SimpleItem } from 'containers/SearchResults/SimpleItem/SimpleItem';

import { getDocumentId } from './utils';

interface Props {
  content?: string;
  href: string;
}

export const ReferenceTooltip: FC<Props> = ({ content, href }) => {
  const docId = getDocumentId(href);
  const isDocument = !!docId;

  const { data, error, isLoading } = useDocDetails({
    docId: String(docId),
    options: {
      enabled: isDocument,
    },
    propertyName: 'id',
  });

  if (!isDocument) {
    return <>External link</>;
  }

  if (error) {
    return <>Error: {JSON.stringify(error)}</>;
  }

  if (isLoading) {
    return <CircularProgress color="secondary" size={18} disableShrink />;
  }

  if (!data) {
    return <SimpleCard content="Reference not found" />;
  }

  return (
    <SimpleItem content={content} href={href} item={data} sx={{ width: 400 }} />
  );
};
