import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import { Optional } from 'common/utils/assert';
import { Avatar } from 'containers/Chat/Message/Avatar';

import NoteAnnotationHighlightItem from '../../NoteAnnotationHighlightItem';

interface Props {
  content: string;
  image: Optional<string>;
}

export const ShortConversationList: FC<Props> = ({ content, image }) => {
  return (
    <Box data-testid="ShortConversationList">
      <Box sx={{ display: 'flex' }}>
        <Avatar />
        <Typography
          sx={{
            flex: 1,
            maxHeight: '48px',
            ml: 1,
            mr: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant="body2"
        >
          {image && (
            <NoteAnnotationHighlightItem
              contentBody={image}
              contentType={'image'}
            />
          )}
          <MarkdownText>{content}</MarkdownText>
        </Typography>
      </Box>
    </Box>
  );
};
