import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useParsedHostname } from 'common/utils/useParsedHostname';
import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { selectIndexCluster } from 'containers/User/user.slice';

import { useAssertTenantSettings } from './useAssertTenantSettings';

export interface UseSearchPayloadReturnValue {
  indexCluster?: string | null;
  searchPayload: SearchPayload;
  searchQuery: string;
}

export function useSearchPayload(): UseSearchPayloadReturnValue {
  const { search } = useLocation();
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);
  const { searchDynamicsFilters, tenantSettings } = useAssertTenantSettings();

  const searchParams = new URLSearchParams(search);
  searchParams.delete('tab');
  searchParams.delete('noteId');
  searchParams.delete('docId');
  searchParams.delete('drawerWidth');
  const searchWithoutTabAndNoteId = searchParams.toString();

  const searchPayload = useMemo<UseSearchPayloadReturnValue>(() => {
    const payload = new SearchPayloadFromURL(
      searchWithoutTabAndNoteId,
      parsedHostname,
      undefined,
      indexCluster,
      searchDynamicsFilters,
      tenantSettings.searchFiltersInitialValues?.retrievalUnit
    );

    return {
      searchPayload: payload.getSearchPayload(),
      searchQuery: searchWithoutTabAndNoteId,
    };
  }, [
    searchWithoutTabAndNoteId,
    parsedHostname,
    indexCluster,
    searchDynamicsFilters,
    tenantSettings.searchFiltersInitialValues?.retrievalUnit,
  ]);

  return searchPayload;
}
