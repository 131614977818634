import React, { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { NoteDetails } from 'api/notesApi/notesApi.types';

import { NoteContentUpdate } from '../../NoteContentUpdate';

interface Props {
  children: ReactNode;
  editMode: boolean;
  editable: boolean;
  onModeToggle?: () => void;
  onUpdate?: (note: NoteDetails) => Promise<void>;
  validate: boolean;
  visible: boolean;
}

export const Content: FC<Props> = ({
  children,
  editable,
  editMode,
  onModeToggle,
  onUpdate,
  validate,
  visible,
}) => {
  if (editMode && onUpdate) {
    return <NoteContentUpdate validate={validate} onUpdate={onUpdate} />;
  }

  if (!visible) {
    return null;
  }

  if (editable) {
    return (
      <Box
        aria-label="click to edit content"
        data-testid="Content"
        sx={
          onModeToggle
            ? { cursor: 'pointer', position: 'relative' }
            : { position: 'relative' }
        }
        onClick={onModeToggle}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box data-testid="Content" sx={{ position: 'relative' }}>
      {children}
    </Box>
  );
};
