import React, { FC } from 'react';

import { CardProps } from '@mui/material';

import { HitType } from 'common/enums';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { useSearchResultsNotes } from '../hooks/useSearchResultsNotes';

import { SimpleCard } from './SimpleCard';

interface Props extends CardProps {
  content?: string;
  href: string;
  item: RetrievalUnitData;
}

export const SimpleItem: FC<Props> = ({ content, href, item, ...props }) => {
  const isNoteType = item.document.type === HitType.Note;

  const {
    query: { data: notesData },
  } = useSearchResultsNotes([item]);

  const noteData = notesData[Number(item.identifier)];

  if (isNoteType && !noteData) return null;

  if (isNoteType) {
    return (
      <SimpleCard
        content={content ?? noteData.highlight ?? noteData.content}
        href={href}
        {...props}
      />
    );
  }

  return (
    <SimpleCard
      content={content ?? item.abstractContent}
      href={href}
      subtitle={item.source}
      title={item.title}
      {...props}
    />
  );
};
