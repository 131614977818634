import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { ListChildComponentProps } from 'react-window';

interface RowProp<T> {
  children: (tag: T) => ReactNode;
  list: T[];
}

export const renderRow =
  <T extends Object>({ children, list }: RowProp<T>) =>
  ({ index, style }: ListChildComponentProps) => {
    const item = list[index];

    if (!item) {
      return null;
    }

    return (
      <Box style={style} sx={{ height: 28, maxHeight: 28, overflow: 'hidden' }}>
        {children(item)}
      </Box>
    );
  };
