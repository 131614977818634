import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { ChatMessageElement, Evidence } from 'api/chatApi/chatApi.types';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { Optional } from 'common/utils/assert';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { ConversationMessageItem } from './ConversationMessageItem';

export interface ConversationListProps {
  chatNoteId?: number;
  children?: ReactNode;
  hits?: RetrievalUnitData[];
  initials?: string;
  isEvidenceDialog?: boolean;
  messages: ChatMessageElement[];
  onEvidenceChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: Optional<string>
  ) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
}

export const ConversationList = ({
  chatNoteId,
  children,
  hits,
  initials,
  isEvidenceDialog,
  messages,
  onEvidenceChunkClick,
  onEvidenceItemClick,
}: ConversationListProps) => (
  <Box data-testid="ConversationList">
    {messages.map((message, index) => (
      <ConversationMessageItem
        chatNoteId={chatNoteId}
        hits={hits}
        initials={initials}
        isEvidenceDialog={isEvidenceDialog}
        key={`message-${index}`}
        message={message}
        onEvidenceChunkClick={onEvidenceChunkClick}
        onEvidenceItemClick={onEvidenceItemClick}
      />
    ))}
    {children}
  </Box>
);
