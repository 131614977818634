import {
  UserSettings,
  UserSettingsPatch,
  PatchPassword,
  IndexCluster,
} from '@zarn/vendor/dist/auth';

import { parseHostname } from 'common/utils/useParsedHostname';

import { DocCardComposition } from './User.enum';
import {
  IndexClusterValue,
  PasswordSettingsValues,
  User,
} from './User.interface';

export const serializeChangePassword = ({
  newPassword,
  oldPassword,
}: PasswordSettingsValues): PatchPassword => ({
  new_password: newPassword,
  old_password: oldPassword,
});

const serializeIndexClusterToIndexClustersObject = (
  indexCluster?: string,
  allIndexClusters?: Array<IndexClusterValue> | null
): IndexCluster[] | null => {
  if (indexCluster && allIndexClusters) {
    //find in the array of object if indexCluster is there
  }
  return null;
};

export const serializeUserSettingsPayload = (
  user: User
): UserSettingsPatch => ({
  feedback_buttons: user.feedbackButtons,
  first_name: user.firstName,
  last_name: user.lastName,
  onboarded: user.onboarded,
  recommendation_subscription: user.recommendations,
  use_openai: user.useOpenAI,
  ...(user.cardStyle ? { card_style: user.cardStyle } : {}),
  index_clusters: serializeIndexClusterToIndexClustersObject(
    user.indexCluster,
    user.allIndexClusters
  ),
  report_email: user.reportsNotifications,
});

const deserializeCardStyle = (cardStyle: string) => {
  if (
    Object.values(DocCardComposition).includes(cardStyle as DocCardComposition)
  ) {
    let result = '';
    Object.entries(DocCardComposition).find(([key, value]) => {
      if (value === cardStyle) {
        result = key;
      }
    });
    return DocCardComposition[result as keyof typeof DocCardComposition];
  } else return DocCardComposition.Default;
};

const deserializeIndexClusters = (indexClusters: IndexCluster[] | null) => {
  if (indexClusters) {
    const indexClusterObjects = indexClusters.map((ic) => {
      return { indexCluster: ic.index_cluster, tenant: ic.tenant };
    });
    return indexClusterObjects;
  } else {
    return null;
  }
};

export const deserializeIndexClusterPerTenant = (
  indexClusters: IndexCluster[] | null
) => {
  const { tenant } = parseHostname();
  let indexCluster = undefined;
  if (indexClusters) {
    const indexClusterObjects = indexClusters.map((ic) => {
      return { indexCluster: ic.index_cluster, tenant: ic.tenant };
    });
    indexCluster = indexClusterObjects.find(
      (ic) => ic.tenant === tenant
    )?.indexCluster;
  }
  return indexCluster;
};

export const deserializeUserSettings = ({
  first_name,
  last_name,
  ...data
}: UserSettings): User => ({
  allIndexClusters: deserializeIndexClusters(data.index_clusters),
  cardStyle: deserializeCardStyle(data.card_style),
  email: data.email,
  feedbackButtons: data.feedback_buttons,
  firstName: first_name,
  indexCluster: deserializeIndexClusterPerTenant(data.index_clusters),
  initials: `${first_name[0]}${last_name[0]}`.toUpperCase(),
  lastName: last_name,
  onboarded: data.onboarded,
  organization: data.affiliation ?? '',
  recommendations: data.recommendation_subscription,
  reportsNotifications: data.report_email,
  useOpenAI: data.use_openai,
});
