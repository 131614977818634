import { getDocIdWithoutChunk } from './documents';

export const endsLikeDoc = (str: string) => str.endsWith('_0');

export const endsLikeChunk = (str: string) => {
  const parts = str.split('_');
  return parts.length === 2 && parts[1] !== '0';
};

export const compareDocuments = (docId1: string, docId2: string) => {
  const doc1 = getDocIdWithoutChunk(docId1);
  const doc2 = getDocIdWithoutChunk(docId2);
  return doc1 === doc2;
};

export const getDocumentMainChunk = (str: string) => {
  if (endsLikeDoc(str)) {
    return str;
  }
  const parts = str.split('_');
  return `${parts[0]}_0`;
};

export const createDocIdFromChunkId = (chunkId: string) => {
  return chunkId.replace(/_\d+$/, '_0');
};
