import React, { FC, useEffect } from 'react';

import { Box } from '@mui/material';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';

import { NoteInChat } from '../NoteInChat/NoteInChat';

interface Props {
  note: NoteDetails;
}
export const ResearchResult: FC<Props> = ({ note }) => {
  const { deleteParams, setParams } = useQuerySearchParams();

  useEffect(() => {
    setParams({ drawerWidth: 'wide' });

    return () => {
      deleteParams(['drawerWidth']);
    };
  }, []);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <NoteInChat noteId={note.id} />
      </Box>
    </>
  );
};
