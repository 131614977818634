import React, { useCallback, useState } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Button, CardActions, CardContent, Popover } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { SearchEngineEnum } from 'common/enums';
import { createDocIdFromChunkId } from 'common/utils/docIdHelpers';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import { useDocTagging } from 'containers/RetrievalUnit/hooks/useDocTagging';
import { useDocTagsInitialValues } from 'containers/RetrievalUnit/hooks/useDocTagsInitialValues';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { SaveDocumentFormValues } from 'containers/SavedDocuments/SavedDocuments.interface';
import TagsAutocomplete from 'containers/Tagging/TagsAutocomplete';
import { DocCardComposition } from 'containers/User/User.enum';

import { useSavedDocument } from '../useSavedDocument';

export type DocTagsProps = {
  actionComposition?: DocCardComposition;
  autoFocus?: boolean;
  docData: RetrievalUnitData;
  searchEngine?: SearchEngineEnum;
  suggestedTags?: TagDetailsBase[];
};

const DocTags = ({
  actionComposition,
  docData,
  searchEngine,
  suggestedTags,
}: DocTagsProps) => {
  const { isAuthenticated } = useAuth();
  const {
    document: { id: docId },
    organizeDocId,
  } = docData;
  const { t } = useTranslation('common');
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);
  const clickAction = useLoggedInFeature(setPopover);
  const { docTags, suggestedTagItems } = useSavedDocument(
    createDocIdFromChunkId(organizeDocId),
    suggestedTags
  );
  const initialValues = useDocTagsInitialValues(docTags);
  const hasSuggestions = initialValues.selectedTags.length > docTags.length;
  const isActive = docTags.length > 0 && isAuthenticated;
  const { onUpdateDocTags } = useDocTagging({ docData, searchEngine });

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      clickAction(event.currentTarget);
    },
    [clickAction]
  );

  const handlePopoverClose = useCallback(() => {
    setPopover(null);
  }, []);

  const handleSubmitForm = useCallback(
    async (
      { selectedTags }: SaveDocumentFormValues,
      { setSubmitting }: FormikHelpers<SaveDocumentFormValues>
    ) => {
      setSubmitting(true);

      await onUpdateDocTags(selectedTags, initialValues.selectedTags);

      handlePopoverClose();
    },
    [docData, handlePopoverClose, searchEngine]
  );

  return (
    <>
      <Button
        aria-label="document tags"
        color={isActive ? 'secondary' : 'primary'}
        data-testid="documentTags"
        size="small"
        startIcon={isActive ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        style={
          actionComposition === DocCardComposition.Compressed
            ? {
                alignSelf: 'start',
                justifyContent: 'start',
                width: '100%',
              }
            : undefined
        }
        onClick={handlePopoverOpen}
      >
        {t('retrievalUnit.actions.tag')}
      </Button>

      <Popover
        anchorEl={popover}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={!!popover}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handlePopoverClose}
      >
        <Formik
          initialValues={initialValues}
          onReset={handlePopoverClose}
          onSubmit={handleSubmitForm}
        >
          {({ dirty, isSubmitting, isValid }) => (
            <Form data-testid="formAddTags">
              <CardContent sx={{ maxWidth: 300 }}>
                <TagsAutocomplete
                  helperText={t('tags:tagsAutocomplete.helperText')}
                  label={t('tags:tagsAutocomplete.label')}
                  name="selectedTags"
                  suggestedTags={suggestedTagItems}
                  autoFocus
                />
              </CardContent>

              <CardActions
                sx={{
                  gap: 1,
                  justifyContent: 'flex-end',
                  pb: 2,
                  pt: 0,
                }}
              >
                <Button type="reset">{t('common:buttons.cancel')}</Button>

                <SubmitButton
                  disabled={!isValid || (!dirty && !hasSuggestions)}
                  eventName={TrackEventName.DocumentTagsEdited}
                  eventProps={{ docId }}
                  isSubmitting={isSubmitting}
                  text={t('common:buttons.save')}
                />
              </CardActions>
            </Form>
          )}
        </Formik>
      </Popover>
    </>
  );
};

export default DocTags;
