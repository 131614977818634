import React, { useMemo } from 'react';

import { List, ListItemSecondaryAction, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FixedSizeList } from 'react-window';

import { DocumentDragItemEnum } from 'containers/RetrievalUnit/RetrievalUnitDraggable';
import {
  selectOwnTags,
  selectOwnTagsLoading,
  selectOwnTagsSortSettings,
  updateOwnTagOrder,
} from 'containers/Tags/ownTagsSlice/ownTags.slice';
import TagItemSettingsButton from 'containers/Tags/TagItemSettingsButton/TagItemSettingsButton';
import { TagDragItemEnum } from 'containers/Tags/TagsList/TagsListOrdering/TagDragItem.enum';
import { useTagsListOrdering } from 'containers/Tags/TagsList/TagsListOrdering/useTagsListOrdering';
import { TagsListSkeleton } from 'containers/Tags/TagsList/TagsListSkeleton';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';

import { renderRow } from '../renderRow';
import TagItemTitle from '../TagItemTitle';
import { TagListDragItem } from '../TagsListOrdering/TagListDragItem';
import { useDocumentDragTagging } from '../TagsListOrdering/useDocumentDragTagging';

import { OwnTagsEmptyState } from './OwnTagsEmptyState';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  actionButtonSelected: {
    opacity: 1,
  },
  addTagButton: {
    borderStyle: 'dashed',
  },
  addTagIconButton: {
    padding: theme.spacing(0),
  },
}));

export type OwnTagsListProps = {
  filteredTagName: string;
};

export const OwnTagsList = ({ filteredTagName }: OwnTagsListProps) => {
  const { t } = useTranslation('tags');
  const ownTags = useSelector(selectOwnTags);
  const ownTagsLoading = useSelector(selectOwnTagsLoading);
  const { sortBy } = useSelector(selectOwnTagsSortSettings);

  const dropTypes = useMemo(() => {
    return !filteredTagName && sortBy === TagsSortByEnum.PriorityOrder
      ? [TagDragItemEnum.Own, DocumentDragItemEnum.Document]
      : [DocumentDragItemEnum.Document];
  }, [filteredTagName, sortBy]);

  const draggable = useMemo(
    () => sortBy === TagsSortByEnum.PriorityOrder,
    [sortBy]
  );

  const { handleDocumentDrop } = useDocumentDragTagging();
  const { handleDrop, handleMove, listItems } = useTagsListOrdering({
    filteredTagName,
    sortBy,
    tags: ownTags,
    updateTagOrderAction: updateOwnTagOrder,
  });

  const classes = useStyles();
  const { pathname } = useLocation();

  const render = useMemo(
    () =>
      renderRow({
        children: (tag) => (
          <TagListDragItem
            draggable={draggable}
            dragType={TagDragItemEnum.Own}
            dropTypes={dropTypes}
            key={tag.id}
            tag={tag}
            title={<TagItemTitle tag={tag} />}
            to={`/tags/${tag.id}`}
            onDocumentDrop={handleDocumentDrop}
            onMove={handleMove}
            onTagDrop={handleDrop}
          >
            <ListItemSecondaryAction
              className={clsx(
                classes.actionButton,
                pathname.startsWith(`/tags/${tag.id}`) &&
                  classes.actionButtonSelected
              )}
              data-testid="ownTagSettingsButton"
            >
              <TagItemSettingsButton tag={tag} />
            </ListItemSecondaryAction>
          </TagListDragItem>
        ),
        list: listItems,
      }),
    [
      draggable,
      dropTypes,
      listItems,
      handleDocumentDrop,
      handleMove,
      handleDrop,
      classes.actionButton,
      classes.actionButtonSelected,
      pathname,
    ]
  );

  if (ownTagsLoading && !ownTags.length) {
    return <TagsListSkeleton />;
  }

  if (!ownTags.length) {
    return <OwnTagsEmptyState />;
  }

  return (
    <List
      aria-label={t('myTags.list.ariaLabel')}
      sx={{
        height: listItems.length > 14 ? 400 : listItems.length * 28,
        width: '100%',
      }}
      disablePadding
    >
      <FixedSizeList
        height={listItems.length > 14 ? 400 : listItems.length * 28}
        itemCount={listItems.length}
        itemSize={28}
        overscanCount={5}
        width="100%"
      >
        {render}
      </FixedSizeList>
    </List>
  );
};
