import React, { useState } from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ResultType } from '@zarn/vendor/dist/query-logging';
import { DocumentItem } from '@zarn/vendor/dist/saved-results';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { AuthorsList } from 'common/components/AuthorsList/AuthorsList';
import DocCites from 'common/components/Docs/DocCites';
import DocDate from 'common/components/Docs/DocDate/DocDate';
import DocMetadata from 'common/components/Docs/DocMetadata/DocMetadata';
import DocRefs from 'common/components/Docs/DocRefs';
import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { HitType, SearchEngineEnum } from 'common/enums';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { DocSourcesEnum } from 'common/utils/documents';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import DocNoteCreate from 'containers/DocNotes/DocNoteCreate';
import DocNotesResults from 'containers/DocNotes/DocNotesResults';
import { FeedbackData } from 'containers/Feedback/Feedback.interface';
import SendFeedback from 'containers/Feedback/SendFeedback/SendFeedback';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import DocTags from 'containers/SavedDocuments/DocTags';

import DocAbstract from '../DocAbstract';
import DocActions from '../DocActions';
import { DocAction } from '../DocActions/DocActions.interface';
import DocFavoriteButton from '../DocFavoriteButton';
import { DocImage } from '../DocImage/DocImage';
import { DocInlineTags } from '../DocInlineTags';
import DocNotesToggle from '../DocNotesToggle';
import DocOwner from '../DocOwner/DocOwner';
import DocOwnerFromOrg from '../DocOwnerFromOrg/DocOwnerFromOrg';
import DocShare from '../DocShare';
import DocSimilarToButton from '../DocSimilarToButton';
import DocSource from '../DocSource';
import DocStatus from '../DocStatus/DocStatus';
import DocTitleWithExternalPDF from '../DocTitleWithExternalPDF';
import DocTitleWithPDF from '../DocTitleWithPDF';
import { RetrievalUnitExplanation } from '../RetrievalUnitExplanation/RetrievalUnitExplanation';
import { RetrievalUnitGithub } from '../RetrievalUnitGithub/RetrievalUnitGithub';
import { RetrievalUnitTweets } from '../RetrievalUnitTweets/RetrievalUnitTweets';
import { useStyles } from '../styles';

const DOCUMENT_TYPES: string[] = [
  HitType.Document,
  HitType.PrivateDocument,
  HitType.ExternalDocument,
];

export type RetrievalUnitCardDefaultProps = {
  cardProps?: CardProps;
  classes?: {
    root?: string;
  };
  data: RetrievalUnitData;
  evidenceNumber?: number;
  explanation?: string;
  feedback?: FeedbackData;
  highlighted?: boolean;
  mapDocIndex?: number;
  moreActions?: DocAction[];
  onDocumentAdd?: (addedDocuments: DocumentItem[]) => any;
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
  pageNumber?: number;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  suggestedTags?: TagDetailsBase[];
  withExplicitFeedback?: boolean;
  withImage?: boolean;
  withSharedNotes?: boolean;
};

export default function RetrievalUnitCardDefault({
  cardProps,
  classes: inputClasses,
  data,
  evidenceNumber,
  explanation,
  feedback,
  highlighted,
  mapDocIndex,
  moreActions,
  onPrivateDocDelete,
  onPrivateDocEdit,
  pageNumber,
  resultType,
  searchEngine,
  searchId,
  suggestedTags,
  withExplicitFeedback,
  withImage = true,
  withSharedNotes,
}: RetrievalUnitCardDefaultProps) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [notesExpanded, setNotesExpanded] = useState<boolean>(false);
  const { me } = useAuth();

  const {
    abstractContent,
    authors,
    document,
    document: { id: docId },
    documentId,
    externalPublicAsset,
    getCitesId = null,
    getRefsId = null,
    getSimilarDocsId,
    numberOfCitations,
    numberOfRefs,
    ontologyId,
    organizeDocId,
    privateAsset,
    publicAsset,
    representations,
    shareUri,
    source,
    status,
    title,
  } = data;

  const docAbstract =
    source === DocSourcesEnum.GitHub ? representations.text : abstractContent;
  const isPrivateDoc = document.type === HitType.PrivateDocument;
  const isDocumentType = DOCUMENT_TYPES.includes(document.type);
  const renderFeedback = !!(withExplicitFeedback && searchId && resultType);
  const renderCites = typeof numberOfCitations === 'number';
  const renderRefs = typeof numberOfRefs === 'number';
  const hasPDF = privateAsset || publicAsset || externalPublicAsset;
  const { isShareToPublicInDoc } = useTenantFeatures();

  const handleNotesAccordionClickAway = () => {
    if (notesExpanded) {
      setNotesExpanded(false);
    }
  };

  return (
    <Card
      {...cardProps}
      aria-label="retrieval unit"
      className={clsx(
        classes.card,
        highlighted && classes.highlighted,
        inputClasses?.root
      )}
      data-testid="RetrievalUnitCardDefault"
      elevation={notesExpanded ? 10 : 1}
    >
      <Grid p={1} spacing={0} container>
        {withImage && <DocImage data={data} />}
        <Grid p={0} item xs>
          <CardContent className={classes.cardContent}>
            {explanation && (
              <RetrievalUnitExplanation explanation={explanation} />
            )}
            {status && <DocStatus status={status} />}
            <div>
              {evidenceNumber && (
                <Typography component="span" pr={1}>
                  {evidenceNumber}.
                </Typography>
              )}
              {mapDocIndex && pageNumber === 1 && (
                <Typography component="span" pr={1}>
                  {mapDocIndex}.
                </Typography>
              )}
              <DocSource
                duplicates={data.duplicates}
                id={docId}
                resultType={resultType}
                searchId={searchId}
                source={data.source}
                uri={data.uri}
              />

              <>
                {!hasPDF && (
                  <DocTitle
                    id={docId}
                    resultType={resultType}
                    searchId={searchId}
                    title={title}
                    uri={data.uri}
                  />
                )}
                {(publicAsset || privateAsset) && (
                  <DocTitleWithPDF
                    documentId={documentId}
                    id={docId}
                    organizeDocId={organizeDocId}
                    resultType={resultType}
                    searchId={searchId}
                    title={title}
                  />
                )}
                {externalPublicAsset && (
                  <DocTitleWithExternalPDF
                    docData={data}
                    id={docId}
                    resultType={resultType}
                    searchEngine={searchEngine}
                    searchId={searchId}
                    title={title}
                  />
                )}
              </>
            </div>

            <DocMetadata>
              <DocDate date={data.date} year={data.year} />
              {authors && <AuthorsList authors={authors} />}
            </DocMetadata>

            {!isDocumentType && document.type && (
              <Chip label={document.type} size="small" />
            )}

            <DocAbstract
              abstractContent={docAbstract}
              highlight={data.highlight}
              id={docId}
              resultType={resultType}
              searchId={searchId}
            />
            <div className={classes.additionalMetadata}>
              {renderCites && numberOfCitations > 0 && (
                <DocCites getCitesId={getCitesId} score={numberOfCitations} />
              )}

              {renderRefs && numberOfRefs > 0 && (
                <DocRefs getRefsId={getRefsId} score={numberOfRefs} />
              )}

              <RetrievalUnitTweets
                documentId={docId}
                numberOfTweets={data.numberOfTweets}
                resultType={resultType}
                searchId={searchId}
                tweets={data.tweets}
              />

              {typeof data.githubScore === 'number' && (
                <RetrievalUnitGithub
                  documentId={docId}
                  repos={data.githubRepos}
                  resultType={resultType}
                  score={data.githubScore}
                  searchId={searchId}
                />
              )}
            </div>
            <DocInlineTags
              docData={data}
              searchEngine={searchEngine}
              suggestedTags={suggestedTags}
            />
          </CardContent>
        </Grid>
      </Grid>

      <Divider />

      <CardActions
        data-testid="RetrievalUnitCardDefault-Actions"
        sx={{ py: 0.5 }}
      >
        <Grid
          alignItems="center"
          columnSpacing={1}
          justifyContent={'flex-end'}
          spacing={0}
          container
        >
          <>
            {isPrivateDoc && data.ownerUuid !== me?.sub && (
              <Grid alignContent="center" container item xs>
                <DocOwnerFromOrg ownerUuid={data.ownerUuid} />
              </Grid>
            )}

            {isPrivateDoc && data.ownerUuid === me?.sub && (
              <Grid alignContent="center" container item xs>
                <DocOwner />
              </Grid>
            )}

            {renderFeedback && (
              <Grid item>
                <SendFeedback
                  initialValues={
                    feedback && {
                      feedbackScore: feedback.score,
                      feedbackText: feedback.text ?? '',
                    }
                  }
                  resultId={docId}
                  resultType={resultType}
                  searchId={searchId}
                  withFeedbackMessage
                />
              </Grid>
            )}

            {getSimilarDocsId && (
              <Grid item>
                <DocSimilarToButton
                  eventName={TrackEventName.FindSimilarClicked}
                  eventProps={{ ontologyId }}
                  getSimilarDocsId={getSimilarDocsId}
                />
              </Grid>
            )}

            <Grid item>
              <DocNotesToggle
                docId={organizeDocId}
                notesExpanded={notesExpanded}
                setNotesExpanded={setNotesExpanded}
                withSharedNotes={withSharedNotes}
              />
            </Grid>

            <Grid item>
              <DocTags
                docData={data}
                searchEngine={searchEngine}
                suggestedTags={suggestedTags}
              />
            </Grid>

            <Grid item>
              <DocFavoriteButton
                docData={data}
                resultType={resultType}
                searchEngine={searchEngine}
                searchId={searchId}
              />
            </Grid>

            {shareUri && isShareToPublicInDoc && (
              <Grid item>
                <DocShare
                  docAuthors={authors}
                  docId={docId}
                  docTitle={title}
                  eventName={TrackEventName.ShareDocumentClicked}
                  resultType={resultType}
                  searchId={searchId}
                  shareUri={shareUri}
                />
              </Grid>
            )}

            <Grid item>
              <DocActions
                data={data}
                moreActions={moreActions}
                onPrivateDocDelete={onPrivateDocDelete}
                onPrivateDocEdit={onPrivateDocEdit}
              />
            </Grid>
          </>
        </Grid>
      </CardActions>

      <Collapse
        in={notesExpanded}
        sx={{ bgcolor: 'grey.100' }}
        timeout="auto"
        unmountOnExit
      >
        <Divider />

        <ClickAwayListener onClickAway={handleNotesAccordionClickAway}>
          <CardContent>
            <Typography variant="h5">
              {t('retrievalUnit.notes.title')}
            </Typography>

            <Box my={2}>
              <DocNoteCreate
                docData={data}
                docId={organizeDocId}
                searchEngine={searchEngine}
              />
            </Box>

            <DocNotesResults
              docId={data.document.id}
              organizeDocId={organizeDocId}
            />
          </CardContent>
        </ClickAwayListener>
      </Collapse>
    </Card>
  );
}
