import React, { FC, MouseEventHandler, useState } from 'react';

import { Box } from '@mui/material';
import { useOpenQAChat } from 'pages/HomePage/SideDrawer/hooks/useOpenQAChat';
import { useOpenMyDocumentsChat } from 'pages/MyDocumentsPage/SideDrawer/hooks/useOpenMyDocumentsChat';

import {
  AnnotationHighlight,
  NoteDetails,
  NoteObjectId,
} from 'api/notesApi/notesApi.types';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { useChatAvailability } from 'common/hooks/useChatAvailability';
import { useSavedPropertyFromNote } from 'common/hooks/useSavedPropertyFromNote';
import { getInitialsFromFullName } from 'common/utils/author';
import { useNoteConversation } from 'containers/Chat/hooks/useConversation/useNoteConversation';
import { useTagChatOpen } from 'containers/Tags/hooks/useTagChatOpen';
import { useUserDetails } from 'containers/Users/hooks/useUserDetails';

import { MoreLessButton } from '../../MoreLessButton/MoreLessButton';
import NoteAnnotationHighlightItem from '../NoteAnnotationHighlightItem';
import { NoteItemContext } from '../noteItem.context';
import { NoteItemContent } from '../NoteItemContent';
import NoteItemMetadata from '../NoteItemMetadata';

import { Actions } from './components/Actions';
import { Content } from './components/Content';
import { useOpenPDFChat } from './hooks/useOpenPDFChat';
import { useStyles } from './useStyles';

export interface NoteItemProps {
  docId?: string | undefined;
  note: NoteDetails;
  onDelete?: (objectId: NoteObjectId, noteId: number) => Promise<void>;
  onHighlightClick?: (highlight: AnnotationHighlight) => void;
  onOpenChatClick?: () => void;
  onUpdate?: (note: NoteDetails) => Promise<void>;
  readMode?: boolean;
  tagId?: number | null;
}

export const NoteItem: FC<NoteItemProps> = ({
  docId,
  note,
  onDelete,
  onHighlightClick,
  onOpenChatClick,
  onUpdate,
  readMode,
  tagId,
}) => {
  const classes = useStyles();

  const { conversation, isChatConversation } = useNoteConversation(note);

  const { annotationHighlight, dateModified, permission } = note;

  const { data: noteOwner } = useUserDetails({
    memberId: note.ownerId ?? '',
    options: { enabled: !!note.ownerId },
  });

  const isHighlightImage =
    annotationHighlight && annotationHighlight.content.type === 'image';

  const isExplainVisible = isFeatureEnabled('ff-multimodal-chat')
    ? !!docId
    : !!(docId && !isHighlightImage);

  const [editMode, setEditMode] = useState(false);

  const { isChatAvailable } = useChatAvailability();
  const isChatEnabled = isChatAvailable;
  const { openTagChat } = useTagChatOpen();
  const { openPDFChat } = useOpenPDFChat({
    docId,
    noteId: note.id,
    onOpenChatClick,
    ...(note.annotationHighlight?.content.body.includes('data:image/png;base64')
      ? { noteImage: note.annotationHighlight?.content.body }
      : {}),
  });
  const { openQAChat } = useOpenQAChat({
    noteContent: note.content,
    noteId: note.id,
    onOpenChatClick,
  });

  const { openMyDocumentsChat } = useOpenMyDocumentsChat({
    noteId: note.id,
    onOpenChatClick,
  });

  const [isShortNote, setIsShortNote] = useState(!readMode);

  const { getSavedPropFromNote } = useSavedPropertyFromNote(note.content);

  const handleOpenChat = async () => {
    const originalUrl = await getSavedPropFromNote('originalUrl');
    const isMyDocumentsPage = originalUrl?.includes('my-documents');

    if (tagId) {
      return openTagChat(tagId, note.id);
    }

    if (docId) {
      return openPDFChat();
    }

    if (isMyDocumentsPage) {
      return openMyDocumentsChat();
    }

    if (!tagId && !docId && !isMyDocumentsPage) {
      return openQAChat();
    }
  };

  const handleChatViewToggle: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setIsShortNote((v) => !v);
  };

  const handleChatClick = () => {
    if (isShortNote) {
      setIsShortNote(false);
      return;
    }
    return handleOpenChat();
  };

  const editModeToggle = () => {
    if (isChatConversation) {
      return handleChatClick();
    }
    if (editable) {
      setEditMode(!editMode);
    }
  };

  const handleHighlightClick = () => {
    if (!onHighlightClick || !annotationHighlight) return;

    onHighlightClick(annotationHighlight);
  };

  const handleToggleShareable = async () => {
    await onUpdate?.({
      ...note,
      shareable: !note.shareable,
    });
  };

  const editable =
    !!onUpdate &&
    (permission === 'modify' || (isChatConversation && isShortNote));

  return (
    <NoteItemContext.Provider value={{ editMode, editModeToggle, note }}>
      <Box
        aria-label="note"
        className={classes.root}
        data-testid="NoteItem"
        display="flex"
        flexDirection="column"
        my={1}
      >
        <Box
          alignItems="baseline"
          display="flex"
          justifyContent="space-between"
        >
          <NoteItemMetadata
            dateModified={dateModified}
            ownerFullName={noteOwner?.fullName}
          />
          <Actions
            docId={docId}
            editable={editable}
            isChatConversation={isChatConversation}
            isChatEnabled={isChatEnabled}
            isExplainVisible={isExplainVisible}
            note={note}
            onDelete={onDelete}
            onEditModeToggle={editModeToggle}
            onOpenChatNote={handleOpenChat}
            onToggleShareable={handleToggleShareable}
          />
        </Box>

        {annotationHighlight && (
          <Box mb={1}>
            <NoteAnnotationHighlightItem
              color={annotationHighlight.color}
              contentBody={annotationHighlight.content.body}
              contentType={annotationHighlight.content.type}
              onClick={onHighlightClick ? handleHighlightClick : undefined}
            />
          </Box>
        )}

        <Content
          editable={editable}
          editMode={editMode}
          validate={!!annotationHighlight}
          visible={!!note.content}
          onModeToggle={readMode ? undefined : editModeToggle}
          onUpdate={onUpdate}
        >
          <NoteItemContent
            content={note.content}
            conversation={conversation}
            editable={editable}
            highlight={note.highlight}
            initials={getInitialsFromFullName(noteOwner?.fullName)}
            isChatConversation={isChatConversation}
            isShortNote={isShortNote}
            noteId={note.id}
            readMode={readMode}
          />
          <MoreLessButton
            isLess={!isShortNote}
            wrapperClassName={classes.moreLess}
            onClick={handleChatViewToggle}
          />
        </Content>
      </Box>
    </NoteItemContext.Provider>
  );
};
