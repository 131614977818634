import { Optional } from 'common/utils/assert';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';

import { useNotesList } from './useNotesList';

export const useSingleNote = (noteId: Optional<string | number>) => {
  const { tenant } = useParsedHostname();
  const { isAuthenticated } = useAuth();

  const note = useNotesList({
    noteIds: noteId ? [+noteId] : [],
    options: {
      enabled: isAuthenticated && !!noteId,
    },
    pageSize: 1,
    tenant,
  });

  return note;
};
