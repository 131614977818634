// Check if the link is a reference link with this format [[1]](http://example.com)
const REF_LINK_REGEX = /^\[\d+\]$/;
export const isReferenceLabel = (label: string): boolean =>
  REF_LINK_REGEX.test(label);

const REF_LINK_ID_REGEX = /\[|\]/g;
export const getReferenceLinkId = (link: string): number =>
  parseInt(link.replace(REF_LINK_ID_REGEX, '') || '', 10);

const DOC_ID_REGEX = /\/documents\/([a-f0-9_]+)/;
export const getDocumentId = (link: string) => {
  const match = link.match(DOC_ID_REGEX);
  return match ? match[1] : null;
};
