import React, { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NoteDetails, NoteObjectId } from 'api/notesApi/notesApi.types';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { openMarkdownAsPdf } from 'common/components/Markdown/exportToPdf';
import {
  MoreActionItem,
  MoreActions,
} from 'common/components/MoreActions/MoreActions';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { selectUser } from 'containers/User/user.slice';

export type NoteItemActionsProps = {
  isChatConversation: boolean;
  note: NoteDetails;
  onDelete: (objectId: NoteObjectId, noteId: number) => Promise<void>;
  onEdit: () => void;
  onOpenSearchDialog: () => void;
};

const NoteItemActions = ({
  isChatConversation,
  note,
  onDelete,
  onEdit,
  onOpenSearchDialog,
}: NoteItemActionsProps) => {
  const { t } = useTranslation(['common', 'chat']);
  const [open, setOpen] = useState(false);
  const user = useSelector(selectUser);
  const userName = `${user?.firstName} ${user?.lastName}`;
  const additionalPdfContent = t('chat:report.pdf.additionalText', {
    userName,
  });

  const moreActionItems: MoreActionItem[] = useMemo(() => {
    const actionItems: MoreActionItem[] = [];

    if (!isChatConversation) {
      actionItems.push({
        Icon: PlagiarismIcon,
        label: t('common:notes.actions.discover'),
        onClick: onOpenSearchDialog,
      });
    }

    if (!isChatConversation) {
      actionItems.push({
        Icon: EditIcon,
        label: t('common:notes.actions.editNote'),
        onClick: onEdit,
      });
    }

    actionItems.push({
      Icon: DeleteIcon,
      label: t('common:notes.actions.deleteNote'),
      onClick: () => setOpen(true),
    });

    if (!isChatConversation) {
      actionItems.push({
        Icon: PictureAsPdfIcon,
        label: t('common:notes.actions.exportToPdf'),
        onClick: () => openMarkdownAsPdf(note.content, additionalPdfContent),
      });
    }

    return actionItems;
  }, [isChatConversation, onEdit, onOpenSearchDialog, t, note.content]);

  const handleDelete = useCallback(async () => {
    setOpen(false);
    await onDelete(note.objectId, note.id);
  }, [onDelete, note]);

  return (
    <>
      <MoreActions
        actions={moreActionItems}
        size="small"
        testId="noteActions"
        title={t('notes.dialog.title')}
      />

      <ExtendedConfirmationDialog
        aria-label="delete note"
        eventName={TrackEventName.NoteDeleted}
        eventProps={{ noteId: note.id }}
        open={open}
        setOpen={setOpen}
        onSubmit={handleDelete}
      >
        {t('notes.dialog.body')}
      </ExtendedConfirmationDialog>
    </>
  );
};

export default NoteItemActions;
